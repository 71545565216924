import * as React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet";

// markup
const IndexPage = () => {
  return (
      <React.Fragment>
            <Helmet title="TeaTak"/>
            <Layout>首页</Layout>
      </React.Fragment>
  )
};

export default IndexPage
